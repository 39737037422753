<template>
  <div class="filterBox">
    <ul class="filter-option-list">
      <li v-for="(item, index) in filterList" :key="index" @click="selectItem(item)">
        <div :class="isSelected == item.itemTypeTxt ? 'filter-option-txt-selected' : 'filter-option-txt'">
          {{item.itemTypeTxt}}
        </div>
        <div v-if="isSelected == item.itemTypeTxt" class="filter-option-dot"></div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    supplierInfo: {
      type: Array,
      default() {
        return []
      }
    },
    isInit: {
      type:Number,
      default: 0
    }
  },
  data () {
    return {
      filterList: [
        {itemTypeTxt: '报价有效期及取消条款', itemType: '报价有效期及取消条款'},
      ],
      isSelected: '酒店会场'
    }
  },
  watch: {
    supplierInfo () {
      if (this.isInit === 1) {
        this.filterList = [...this.supplierInfo,...this.filterList]
        this.$emit('updateItem', this.filterList[0])
      }
    }
  },
  created() {
  },
  methods: {
    selectItem (item) {
      if (this.isSelected == item.itemTypeTxt) {
        return false
      }
      this.isSelected = item.itemTypeTxt
      this.$emit('updateItem', item)
    }
  }
}
</script>
<style scoped lang="scss">
.filterBox {
  width: 100vw;
  overflow: hidden;
  max-height: 0.3rem;
}
.filter-option-list {
  width: 100vw;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  flex-wrap: nowrap;
  min-height: 0.4rem;
  padding-left: 0.1rem;
  li {
    min-width: 0.3rem;
    position: relative;
    margin-right: 0.1rem;
    flex-shrink: 0;
    height: 0.3rem;
    text-align: center;
    padding: 0 0.1rem;
  }
  .filter-option-txt {
    font-size: 0.14rem;
    font-weight: 500;
    color: #90939a;
    line-height: 0.22rem;
    width: 100%;
  }
  .filter-option-txt-selected {
    font-size: 0.15rem;
    color: #13161B;
    font-weight: 800;
    line-height: 0.22rem;
    width: 100%;
  }
  .filter-option-dot {
    width: 100%;
    height: 0.04rem;
    background: var(--themeColor);
    border-radius: 0.01rem;
    margin: 0 auto;
  }
}
</style>
