<template>
  <div>
    <div class="venueCard">
      <a-collapse v-model="activeKey" expandIconPosition="right" :bordered="false">
        <a-collapse-panel key="1" header="需求信息">
          <itemInfo :venueItem='venueItem'></itemInfo>
        </a-collapse-panel>
        <a-collapse-panel key="2" :header="'费用类型:' + venueItem.itemTypeTxt">
          <p v-if="[2].includes(venueItem.itemType)" class="quoteTips">特别提示：请按照预计人数（非保底人数）计算并填写总金额</p>
          <p v-if="[3].includes(venueItem.itemType)" class="quoteTips">如10间房以上或因其他原因而需要设定担保数量的情况，请在备注中说明</p>
          <p v-if="[4].includes(venueItem.itemType)" class="quoteTips">请务必注明会议室名称、面积、楼层、超出参考价原因</p>
          <a-form :form="form">
            <a-row :gutter='16'>
              <template v-for="(item, index) in formList">
                <a-Col :span="['AutoComplete'].includes(item.type) ? 24 : item.span || 12" :key='index'>
                  <a-form-item :label="item.txt" :key='"form-"+index'>
                    <a-input-number v-if="['Number'].includes(item.type)" :disabled='item.disabled || !isEdit'
                    @change="(val) => (handleChange(item.operate, val))" style="width: 100%;" :min='0' :placeholder='item.placeholder' 
                    v-decorator="[item.val, {rules: [{ required: item.required, message: item.message }]}]"/>

                    <a-radio-group v-else-if="['radio'].includes(item.type)" :disabled='item.disabled || !isEdit' :placeholder='item.placeholder' 
                    v-decorator="[item.val, {rules: [{ required: item.required, message: item.message }]}]">
                      <a-radio v-for="(ele, i) in item.options" :key="i" :value="ele.val"> {{ele.txt}}</a-radio>
                    </a-radio-group>

                    <a-select v-else-if="['select'].includes(item.type)" style="width: 100%" :disabled='item.disabled || !isEdit' :options='item.options'
                    :placeholder='item.placeholder' v-decorator="[item.val, {rules: [{ required: item.required, message: item.message }]}]"></a-select>

                    <FinderFrame v-else-if="['file'].includes(item.type)" :gateway="gateway" ref="FinderFrame" :isPreview="true" :h5FilePreview="false" :actions="actions"
                    :onlyPreview="!isEdit" :filterData="mappingData" :isMapping="true" :uploadTimeout="120000" @uploadCallback="() => {}" />

                    <a-textarea v-else-if="['TextArea'].includes(item.type)" :autoSize="{ minRows: 2, maxRows: 6 }" :disabled='item.disabled || !isEdit' 
                    :placeholder='item.placeholder' v-decorator="[item.val, {rules: [{ required: item.required, message: item.message }]}]"/>

                    <a-auto-complete v-else-if="['AutoComplete'].includes(item.type)" :disabled='item.disabled || !isEdit' :placeholder='item.placeholder'
                    @change='onChange' option-label-prop="value" :dataSource="options" v-decorator="[item.val, {rules: [{ required: item.required, message: item.message }]}]"/>

                    <div v-else-if="['price'].includes(item.type)">
                      <a-input-number :disabled='item.disabled || !isEdit' style="width: 100%;" :placeholder='item.placeholder' @change='handleChangePrice' :min="0" 
                      v-decorator="[item.val, {rules: [{ required: item.required, message: item.message }]}]"/>
                    </div>

                    <a-input v-else style="width: 100%" :disabled='item.disabled || !isEdit' :placeholder='item.placeholder'
                    v-decorator="[item.val, {rules: [{ required: item.required, message: item.message }]}]"/>
                  </a-form-item>
                  <div v-if="['AutoComplete'].includes(item.type) && lowPrice" class='errorTips'>最低价：{{lowPrice}}</div>
                </a-Col>
                <a-Col :span="12" v-if="showBeyondCause && lowPrice && ['price'].includes(item.type)" :key="index + 'beyondCause'">
                  <a-form-item label="超最低价原因">
                    <a-textarea :autoSize="{ minRows: 1, maxRows: 6 }" placeholder='请输入超最低价原因' v-decorator="['beyondCause', {rules: [{ required: true, message: '请输入超最低价原因'}]}]"/>
                  </a-form-item>
                </a-Col>
              </template>
            </a-row>
          </a-form>
        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>
</template>
<script>
import {FinderFrame} from "smart-filer-vue";
import "smart-filer-vue/lib/index.css";
import itemInfo from "../itemInfo.vue";
export default {
  name: 'demandCard',
  components: {
    FinderFrame,
    itemInfo
  },
  props: {
    venueItem: {
      type: Object,
      default(){
        return {}
      }
    },
    isGsk: {
      type: Boolean,
      default: false
    },
    formList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data () {
    return {
      quotation: {},
      formData: [],
      fileKeys: [],
      options: [],
      activeKey: ['1','2'],
      lowPrice: undefined,
      showBeyondCause: false,
      form: this.$form.createForm(this, {
        name: 'editquote', // 表单域内字段 id 的前缀
      }),
      supplierInfo: this.$SmartStorage.get('supplierInfo'),
      isShow: true,
      isEdit:true,
      gateway: process.env.VUE_APP_GATEWAY,
      actions: [
        {actionType: "carame", actionLabel: "拍照"},
        { actionType: "album", actionLabel: "文件", accepts: "*/*,.doc,.docx,.pdf,.xls,.xlsx" }
      ],
      mappingData: {
        processId: this.$SmartStorage.get('proposalId'),
        itemId: this.venueItem.itemId,
        supplierId: this.$SmartStorage.get('supplierId'),
        catalog: "报价文件"
      }
    }
  },
  watch: {
    formList(){
      this.int()
    },
    venueItem () {
      if ([3].includes(this.venueItem.itemType)){
        this.GetItemVenueAndRooms()
      }
    }
  },
  created() {
    this.isEdit = this.$SmartStorage.get('isEdit')
    if ([3].includes(this.venueItem.itemType)){
      this.GetItemVenueAndRooms()
    }
  },
  methods: {
    async GetItemVenueAndRooms () {
      let params = {
        proposalId: this.$SmartStorage.get('proposalId'),
        itemId: this.venueItem.itemId,
        supplierId: this.$SmartStorage.get('supplierId'),
      }
      let res = await this.$service.GetItemVenueAndRooms(params)
      if (res && res.success) {
        const content = res.content.map(ele => ({
          ...ele,
          value: ele.name || '',
          text: `${ele.name || ''}`,
        }));
        this.options = content
      }
    },
    onChange (searchText) {
      this.lowPrice = undefined;
      this.options.forEach(ele => {
        if ([searchText].includes(ele.name)) {
          this.lowPrice = Number(ele.lowPrice);
        }
      });
    },
    handleChangePrice (data) {
        this.showBeyondCause = data < this.lowPrice;
        const roomNum = this.form.getFieldValue('qty') || 1;
        const days = this.form.getFieldValue('qtyExt') || 1;
        const total = data * roomNum * days;
        this.form.setFieldsValue({'amount': total});
    },
    int () {
      let data = this.venueItem
      let formItems = this.formList
      if (data.expenses.length && formItems) {
        const expenses = { ...data, ...data.expenses[0] };
        if ([null, undefined].includes(expenses.agreementPrice)) {
            expenses.agreementPrice = this.isGsk && !this.supplierInfo.inWhiteList;
        }
        const fieldsValue = {};
        formItems.forEach(ele => {
          const values = ![null, undefined].includes(expenses[ele.val]) ? expenses[ele.val] : ele.details
          fieldsValue[ele.val] = values;
        });
        this.$nextTick(() => {
          this.form.setFieldsValue(fieldsValue);
        })
      }
    },
    handleChange(operate, val) {
      console.log(val)
      let form = this.form
      console.log(form)
      if (operate && operate.length) {
        operate.forEach(ele => {
          const operateTxt = ele.operate ? ele.operate.replace(/"/g, "") : null;
          const result = ele.operate ? eval(operateTxt) : 0;
          this.form.setFieldsValue({ [ele.result]: result });  // 使用 this 访问 form 对象
        });
      }
    },
    editInfo () {
        return this.form.validateFields().then(values => {
        if (values.minQty > values.qty) {
            this.$message.error('保底数量需在预计人数范围内');
            return {result: false};  // 或者抛出一个错误
        }
        const params = this.createSaveParams(values);
        return {result: true, params:params};  // 返回 params 给父组件
      }).catch(error => {
        console.error('Validation failed:', error);
        return { result: false };
      });
    },
    createSaveParams (values) {
      let selectData = this.venueItem
      let res = this.$refs.FinderFrame.fileList || []
      let fileKeys = res.map(ele => ele.fileKey)
      const taxRate = Number(values.taxRate) / 100;
        const quote = {
            ...values,
            costType: selectData.costType,
            tax: Number((values.amount / (1 + taxRate) * taxRate).toFixed(2)),
            files: fileKeys,
        };
        if (selectData.expenses?.[0].expenseId) {
            quote.expenseId = selectData.expenses[0].expenseId;
        }
        if (this.lowPrice !== undefined) {
            quote.lowPrice = this.lowPrice;
        }
        return {
          tenantId: this.$SmartStorage.get('tenantId'),
          miceId: this.$SmartStorage.get('proposalId'),
          orderId: this.$SmartStorage.get('orderId') || null,
          itemId: this.venueItem.itemId,
          supplierId: this.$SmartStorage.get('supplierId'),
          quotes: [quote],
        };
    },
  }
}
</script>
<style scoped lang="scss">
.venueCard {
  box-shadow: 0 0.02rem 0.08rem 0 rgba(0, 0, 0, 0.15);
  border-radius: .04rem;
  margin: .1rem;
  background: #ffffff;
  position: relative;
  text-align: left;
  .errorTips {
    position:absolute;
    bottom: 0;
  }
}
</style>
<style lang="scss">
.venueCard {
  .ant-collapse-borderless{
    background: #fff;
  }
  .ant-collapse-header {
    font-weight: bold;
    font-size: 17px;
    color: #13161A;
  }
}
</style>